import { A } from "@solidjs/router";
import { ButtonProps } from "./model";

export function Button({ theme = "light", outlined = false, link, onClick, text, cls }: ButtonProps) {
  const lightTheme = theme == "light";

  const handleOnClick = (e) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  // console.log("button link is :: ", link);
  return (
    <A
      href={link ?? ""}
      onClick={handleOnClick}
      class={`${lightTheme ? "bg#n-50 text#p" : "bg#p text#n-50"} ${
        outlined && lightTheme && "border-2 border#p border-solid"
      }
      ${outlined && !lightTheme && "border-2 border#n-50 border-solid"}
      flex items-center justify-center px-15px h-44px text-16px cursor-pointer no-underline  ${cls}`}
    >
      {text}
    </A>
  );
}
